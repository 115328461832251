import React from "react"
import { Typography, Grid } from "@material-ui/core"

import { useStyles } from "./styles"

interface IntroductionProps {
  title?: string
  introduction?: string[]
  logo?: string
}

const Introduction = ({ title, introduction, logo }: IntroductionProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        {logo ? (
          <Grid container>
            <Grid item xs={12} sm={2}>
              <img src={logo} className={classes.logo} />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
        )}

        {introduction &&
          introduction.map((item, index) => (
            <Typography
              key={`services-introduction-${index}`}
              variant="body2"
              className={classes.description}
            >
              {item}
            </Typography>
          ))}
      </div>
    </div>
  )
}

export default Introduction
