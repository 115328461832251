import * as React from "react"
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@material-ui/core"

import { additionalFeaturesList } from "./data"
import { useStyles } from "./styles"

const title = "Additional Features"

const AdditionalFeatures = () => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="subtitle1" className={classes.heading}>
        {title}
      </Typography>
      <Grid container className={classes.root}>
        {additionalFeaturesList.map((item, index) => (
          <Grid item xs={12} sm={12} md={3} className={classes.gridItem}>
            <Card
              raised={false}
              className={classes.card}
              key={`additional-features-item-${index}`}
            >
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <div className={classes.cardHeading}>
                    <div className={classes.icon}>
                      <img src={item.icon} alt={classes.itemIcon} />
                    </div>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.title}
                    >
                      {item.title}
                    </Typography>
                  </div>
                  <Typography
                    variant="subtitle2"
                    className={classes.description}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
  // <div className={classes.root}>
  //   <div className={classes.innerWrapper}>
  //     <Typography variant="subtitle1" className={classes.title}>
  //       {title}
  //     </Typography>
  //     <Grid container spacing={10} className={classes.listWrapper}>
  //     {additionalFeaturesList.map((item, index) => (
  //       <Grid item xs={12} sm={6} md={3}
  //         key={`additional-features-item-${index}`}
  //         className={classes.item}
  //       >
  //         <div className={classes.itemInnerWrapper}>
  //           <img src={item.icon} className={classes.itemIcon} />
  //           <Typography variant="subtitle2" className={classes.itemTitle}>
  //             {item.title}
  //           </Typography>
  //           <Typography variant="body2" className={classes.itemDescription}>
  //             {item.description}
  //           </Typography>
  //         </div>
  //       </Grid>
  //     ))}
  //     </Grid>
  //   </div>
  // </div>
}

export default AdditionalFeatures
