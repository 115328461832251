import * as React from "react"
import { Grid, Typography } from "@material-ui/core"

import backgroundImageUrl from "../../../../media/home/management-background.jpg"
import deviceImageUrl from "../../../../media/home/devices.png"
import rivvitImageUrl from "../../../../media/rivvit-logo.png"

import LearnMoreButton from "../../../components/LearnMoreButton"

import { useStyles } from "./styles"

const title ="Interested in a demo?"

const ContactUs = () => {
  const classes = useStyles({ backgroundImageUrl })

  return (
    <div className={classes.root}>
      <Grid container spacing={10} className={classes.innerWrapper}>
        <Grid item xs={12} sm={12} md={6}
          key={`technology-contact-us-left`}
          className={classes.leftSide}
        >
          <img src={deviceImageUrl} className={classes.deviceImage} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}
          key={`technology-contact-us-right`}
          className={classes.rightSide}
        >
          <img src={rivvitImageUrl} className={classes.rivvitLogoImage} />
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
          <LearnMoreButton to="contact-us" label="Contact Us" />
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactUs
