import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px 60px 5px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    lineHeight: 1.2,
    maxWidth: 1300,
    fontSize: "2rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
      textAlign: "left"
    },
  },
  logo: {
    maxHeight: 70,
    maxWidth: "100%",
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      margin: "0 auto",
      marginBottom: 10,
      display:"block"
    },
  },
  description: {
    textAlign: "left",
    maxWidth: 1300,
    marginTop: 30,
  },
}))
