import documentLibraryIcon from "../../../../media/technology/document-library-icon.png"
import billingIcon from "../../../../media/technology/billing-icon.png"
import complianceIcon from "../../../../media/technology/compliance-icon.png"
import reconciliationIcon from "../../../../media/technology/reconcilliation-icon.png"

export const additionalFeaturesList = [
  {
    title: "Document Library",
    description: "Upload and retrieve important documents and data files",
    icon: documentLibraryIcon
  },
  {
    title: "Billing",
    description: "Set up, calculate, and produce invoices for various client fee structures",
    icon: billingIcon
  },
  {
    title: "Compliance",
    description: "Configure investment guidelines, view compliance history, and perform hypothetical trade analysis",
    icon: complianceIcon
  },
  {
    title: "Reconciliation",
    description: "Configure matching rules and exception thresholds to compare data sets and manage exceptions",
    icon: reconciliationIcon
  }
]
