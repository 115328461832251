import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  root: {
    height: 50,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    background: "#27aae1",
    color: "#fff",
    border: "1px solid #27aae1",
    fontSize: "1.5rem",
    textTransform: "capitalize",
    marginTop: 60,
    marginBottom: 30,
    padding: "0 30px",
    textDecoration: "none",
  },
}))
