import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: ({ backgroundImageUrl }: any) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }),
  innerWrapper: {
    maxWidth: 1440,
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  leftSide: {
    padding: "0 60px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "20px !important",
    },
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 60px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "20px !important",
    },
  },
  deviceImage: {
    width: "100%",
  },
  rivvitLogoImage: {
    width: 200,
  },
  title: {
    //color: "#27aae1",
    lineHeight: 1.2,
    marginTop: 30,
    [theme.breakpoints.up("lg")]: {
      //maxWidth: 350,
    },
  },
}))
