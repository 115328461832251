import { makeStyles } from "@material-ui/core/styles"

interface HeroProps {
  backgroundImage: string
}

export const useStyles = makeStyles((theme) => ({
  heroLarge: ({ backgroundImage }: HeroProps) => ({
    position: "relative",
    width: "100%",
    //height: "75vw",
    minHeight: 550,
    maxHeight: 680,
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      minHeight: 450,
      maxHeight: 680,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: "#2d65b2",
      //height: "30vw",
      minHeight: 300,
      maxHeight: 680,
    },
  }),
  heroSmall: ({ backgroundImage }: HeroProps) => ({
    height: 300,
    position: "relative",
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width:"100%",
    [theme.breakpoints.down('sm')]: {
      // backgroundImage: 'none',
      // backgroundColor: "#2d65b2",
      height: "30vw",
      minHeight: 150,
      maxHeight: 300,
    },
  }),
  heroImage: {
    width: "100%",
    minHeight: 400,
  },
  wrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      position: "relative",
    },
  },
  largeWrapper: {

  },
  heroTitle: {
    color: "#fff",
    textAlign: "center",
    marginTop: "4vw",
    marginBottom: 0,
    textShadow: "3px 3px 3px #000",
    fontSize: "3rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "2.4rem"
    },
    [theme.breakpoints.down('sm')]: {
      //textShadow: 'none',
      fontSize: "2.4rem"
    },
  },
  heroDescription: {
    maxWidth: 1040,
    color: "#fff",
    lineHeight: 1.2,
    marginTop: 30,
    textAlign: "center",
    textShadow: "3px 3px 3px #000",
    fontSize: "2rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.8rem"
    },
    [theme.breakpoints.down('sm')]: {
      textShadow: 'none',
      fontSize: "1.5rem"
    },
  }
}))
