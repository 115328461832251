import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  // root: {
  //   position: "relative",
  //   flexGrow: 1,
  //   display: "flex",
  //   justifyContent: "center",
  //   paddingTop: 60,
  //   paddingBottom: 10,
  //   [theme.breakpoints.down("sm")]: {
  //     padding: 20,
  //   },
  // },
  // innerWrapper: {
  //   width: "100%",
  //   maxWidth: 1440,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  // title: {
  //   //
  // },
  // listWrapper: {
  //   marginTop: 20,
  // },
  // item: {
  //   //
  // },
  // itemInnerWrapper: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  // itemIcon: {
  //   maxWidth: 60,
  //   maxHeight: 60,
  // },
  // itemTitle: {
  //   //color: "#2b67b1",
  //   textAlign: "center",
  //   marginTop: 10,
  // },
  // itemDescription: {
  //   textAlign: "center",
  //   marginTop: 10,
  //   lineHeight: "1.75rem",
  //   fontSize: "1.25rem"
  // },
  ////////////////////////////////////
  root: {
    maxWidth: 1450,
   margin: "0 auto",

  },
  gridItem:{ 
    padding: 10
  },
  card: {
    display: 'flex',
    margin: 10,
    height: "100%",
    padding: "20px 20px 0",
    borderRadius: 0,
    backgroundColor: "#f0f0f0"
  },
  heading: {
    fontSize: "2.5rem",
    textAlign: "center",
    margin: "30px 15px"
  },
  cardHeading: {

  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  icon: {

    margin:"0 auto 30px",
    "& > img": {
      maxWidth: 60,
      maxHeight: 65,
      display: "block",
      margin: "0 auto"
    }
  },

  title: {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Roboto Bold"
  },
  description: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 300,
    fontFamily: "Roboto"
  },
}))
