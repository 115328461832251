import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
import Introduction from "../../components/Introduction"
import TechnologyContent from "../../sections/technology/TechnologyContent"
import ContactUs from "../../sections/technology/ContactUs"
import AdditionalFeatures from "../../sections/technology/AdditionalFeatures"
import LearnHow from "../../components/LearnHow"
import NextService from "../../sections/services/NextService"
import rivvitLogo from "../../../media/rivvit-logo.png"
import technologyHeroBgUrl from "../../../media/technology-banner-overlay.png"

const technologyHeroTitle = "Technology"
const technologyHeroDescription = ""
const introductionTitle = "Our proprietary managed data and reporting platform, Rivvit, integrates disparate data to deliver a single source of truth that is actionable and reliable"
const para = ["By creating a single source of truth for your portfolio data, Rivvit provides the foundation to produce outputs required for client reporting, compliance, performance, attribution, fee calculations, and risk management."]
const learnHowTitle = "Read the case study"
const learnHowDescription = "Learn how an insurance company outsourced data management and reporting using Grandview’s Rivvit platform"
const learnHowLink = "case-study/rivvit"

const TechnologyPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Technology" />
    <Hero
      home={false}
      backgroundImage={technologyHeroBgUrl}
      title={technologyHeroTitle}
      description={technologyHeroDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
      logo={rivvitLogo}
    />
    <TechnologyContent />
    <ContactUs />
    <AdditionalFeatures />
    <LearnHow
      title={learnHowTitle}
      description={learnHowDescription}
      to={learnHowLink}
    />
    <NextService
      text="Next, view our client success stories"
      link="client-work"
    />
  </Layout>
)

export default TechnologyPage
