import * as React from "react"
import { Link } from "@reach/router"
import Button from '@material-ui/core/Button';

import { useStyles } from "./styles"

interface LearnMoreProps {
  to: string,
  label?: string
}

const LearnMoreButton = ({ to, label }: LearnMoreProps) => {
  const classes = useStyles()

  return (
    <Button href={`/${to}`} variant="contained" color="primary" className={classes.root}>
      {label ? label : "Learn More"}
    </Button>
  )
}

export default LearnMoreButton
