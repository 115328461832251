import * as React from "react"
import { Box, Typography } from "@material-ui/core"

import { useStyles } from "./styles"

interface HeroProps {
  backgroundImage: string,
  title: string,
  description: string,
  home?: boolean
}

const Hero = ({ backgroundImage, title, description, home }: HeroProps) => {
  const classes = useStyles({ backgroundImage })
  
  return (
    <Box className={home ? classes.heroLarge : classes.heroSmall}>
      <Box className={classes.wrapper}>
        <Typography variant="h1" className={classes.heroTitle}>
          {title}
        </Typography>
        {home && 
        <Typography variant="subtitle1" className={classes.heroDescription}>
          {description}
        </Typography>
        }
      </Box>
    </Box>
  )
}

export default Hero
