import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: 40,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    padding: "60px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  leftWrapper: {
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      maxHeight: "200px"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      maxHeight: "200px"
    },
  },
  description: {
    lineHeight: 1.4,
  },
  navLink: {
    textDecoration: "none",
  },
  navLinkTitle: {
    color: "#2697db",
  },
}))
