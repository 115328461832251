import * as React from "react"
import { Link } from "@reach/router"
import { Grid, Typography } from "@material-ui/core"

import clientProfileIcon from "../../../media/services/client-profile-icon.jpg"

import { useStyles } from "./styles"

interface LearnHowProps {
  title: string,
  description: string,
  to: string,
}

const LearnHow = ({ title, description, to }: LearnHowProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={3} xl={3}
              className={classes.leftWrapper}
            >
              <img
                src={clientProfileIcon}
                alt="Client profile icon"
                className={classes.image}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={9} xl={9}
              className={classes.rightWrapper}
            >
              <Typography variant="body1" className={classes.description}>
                {description}
              </Typography>
              <Link to={`/${to}`} className={classes.navLink}>
                <Typography variant="body1" className={classes.navLinkTitle}>
                  {title} >
                </Typography>
              </Link>
            </Grid>
          </Grid>
      </div>
    </div>
  )  
}

export default LearnHow
