import supportsDataElementsImg from "../../../../media/technology/call-to-action-rivvit-supports-data-elements.jpg"
import gettingStartedImg from "../../../../media/technology/call-to-action-image-getting-started.jpg"

export const technologyContentList = [
  {
    title: "Data Management",
    description: [
      "Data from disparate sources is loaded into Rivvit in native formats, and transformed into standardized formats using our proprietary rules engine. ",
      "The cleansed data is mapped and loaded into our investment data warehouse with a dedicated client data mart to support standard and custom data sets. "
    ]
  },
  {
    title: "Reporting and Analytics",
    description: [
      "Rivvit includes standard reports, dashboards, and calculation functions that are common across the investment industry. ",
      "We also offer flexibility for custom data models, reports, dashboards, and calculations. ",
      "Our data architecture delivers unmatched transparency into underlying data for key calculations. "
    ]
  },
  {
    title: "Managed Services",
    description: [
      "We provide setup and oversight of inbound and outbound data feeds. ",
      "Our team handles system configuration, business rules, and custom reporting for your specific business needs. ",
      "We apply best practices for data governance to ensure quality data. "
    ]
  }
]

export const learnMoreList = [
  {
    title: "Rivvit supports common data elements",
    linkTitle: "Learn More",
    link: "/technology/common-data-elements",
    backgroundImage: supportsDataElementsImg
  },
  {
    title: "Getting started is fast and easy",
    linkTitle: "Learn How",
    link: "/technology/getting-started-with-rivvit",
    backgroundImage: gettingStartedImg
  }
]
