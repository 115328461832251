import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    textAlign: "center",
  },
  listWrapper: {
    marginTop: 40,
  },
  item: {
    //
  },
  itemInnerWrapper: {
    position: "relative",
    height: "100%",
    minHeight: 150,
    backgroundColor: "#f0f0f0",
    padding: "40px",
  },
  itemTagImage: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "30%",
  },
  itemTitle: {
    //color: "#2b67b1",
    textAlign: "center",
  },
  itemDescription: {
    textAlign: "left",
    fontSize: "1rem",
    lineHeight: 1.5,
    marginTop: 15
  },
  learnMoreSection: {
    marginTop: 20,
  },
  learnMoreItemContainer: {
    position: "relative",
  },
  learnMoreItemInnerWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  learnMoreItemImage: {
    width: "100%",
  },
  learnMoreTitle: {
    color: "#fff",
    textAlign: "center",
    textShadow: "1px 1px 2px #000"
  },
  learnMoreLink: {
    textDecoration: "none",
    textTransform: "uppercase",
    marginTop: 10,
    textShadow: "1px 1px 2px #000"
  },
  learnMoreLinkText: {
    color: "#fff",
  },
}))
